<template>
    <div>
        <div class="all" :style="{backgroundImage: 'url(' + bg + ')'}">
            
            <div class="all-txt">
                <div class="main-one">
                    <div v-html="contentn"></div>
                </div>

            </div>
            <!-- <div> -->
                <!-- <div class="erweima-txt">小程序码</div> -->
                <!-- <el-image class="erweima" :src="urlimag"></el-image> -->
            <!-- </div> -->
            <!-- <div class="taobao-button">PC链接：{{config.jgpclink}}</div> -->
            <!-- <div class="taobao-button">加国淘宝 代购代发 请扫描登入微商相册：二维码</div> -->
        </div>
    </div>
</template>


<script>
import {apptb,getset} from '@/api/index'
export default {
    name:'Taobao',
    data(){
        return{
            config:{},
            bg:'',
            urlimag:'',
            contentn:'',
        }
    },
    created(){
        getset({name:'taobao_bg'}).then(res=>{this.bg = this.$store.state.baseUrl + res.data})
        getset({name:'taoaboTxt'}).then(res=>{this.contentn = res.data})
        // this.getTaobao()
    },
    methods:{
        getTaobao(){
            apptb().then(res=>{
                this.config = res.data
                this.urlimag = this.$store.state.baseUrl +  res.data.xcxqrcode
            })
        },
    }
}
</script>


<style scoped>
.zhanwei{
    height: 120px;
}
.all{
    width:100%;
    /* height:1000px; */
    background-color: #fff;
    background-size: cover;
    background-repeat:no-repeat; 
    background-attachment:fixed;
    /* text-align: center; */
    color:#ffffff;
}
.all-txt{
    padding-top:330px;
    /* font-size: 56px;
    font-weight: bold; */
}
.taobao-button{
    width: 1094px;
    height: 107px;
    font-size: 28px;
    line-height:107px;
    background: #F6AF16;
    border-radius: 54px;
    margin:auto;
}
.erweima{
    width:307px;
    height:307px;
    margin:58px  0  116px 0;
}
.erweima-txt{
    margin-top:96px;
    font-size: 26px;
    font-weight: 500;
    color: #FFFFFF;
}
.main-one{
    
    width: 80%;
    margin: auto;
    padding: 40px;
    border: 1px solid #ccc;
    background-color: rgba(255, 255, 255, 0.7)  !important;
    transition: 1s;
    box-sizing: border-box;
}
</style>